import React from "react";
import "../css/Feature_section.scss";

const Feature_section = () => {
  return (
    <section className="bg-white text-dark p-5" id="price">
      <div className="p-0 m-0 d-flex justify-content-center flex-column w-100">
        <h2 className="text-center mb-4">Прайс-лист</h2>

        <p className="text-center">
          <strong>Адрес:</strong> Ульяновская обл., Ульяновск, Локомотивная ул., 108, р-н Железнодорожный
        </p>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Вид работы</th>
                <th scope="col">Цена за м²</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Гидроизоляция бетонной поверхности</td>
                <td>100 ₽</td>
              </tr>
              <tr>
                <td>Демонтаж напольных покрытий</td>
                <td>100 ₽</td>
              </tr>
              <tr>
                <td>Демонтаж паркета</td>
                <td>200 ₽</td>
              </tr>
              <tr>
                <td>Нанесение покрытия на деревянные полы</td>
                <td>100 ₽</td>
              </tr>
              <tr>
                <td>Обеспыливание бетонных полов</td>
                <td>50 ₽</td>
              </tr>
              <tr>
                <td>Укладка ламината</td>
                <td>250 ₽</td>
              </tr>
              <tr>
                <td>Укладка линолеума</td>
                <td>200 ₽</td>
              </tr>
              <tr>
                <td>Установка плинтусов</td>
                <td>100 ₽</td>
              </tr>
              <tr>
                <td>Сухая стяжка пола</td>
                <td>500 ₽</td>
              </tr>
              <tr>
                <td>Укладка кварцвинила</td>
                <td>250 ₽</td>
              </tr>
              <tr>
                <td>Цементная стяжка пола</td>
                <td>500 ₽</td>
              </tr>
              <tr>
                <td>Укладка террасной доски</td>
                <td>250 ₽</td>
              </tr>
              <tr>
                <td>Укладка шпунтованной доски</td>
                <td>300 ₽</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="text-center mt-4 px-3 py-3 position-relative"
          style={{ backgroundColor: "#f8f9fa", borderRadius: "15px", boxShadow: "0 4px 6px rgba(0,0,0,0.1)" }}
        >
          <h4 className="text-hover-animated position-relative pt-2 z-2" style={{ color: "#0056b3" }}>
            Условия работы
          </h4>
          <p className="text-hover-animated position-relative z-2">
            Работаем ежедневно: <strong>08:00‑18:00</strong> <span className="text-muted">(Время для связи: до 20:00)</span>
            <br />
            <span className="badge bg-primary">Минимальная сумма заказа: 1000 ₽</span>
            <br />
            Подробности: доступно <strong>1-4 мастера</strong>, опыт более <strong>10 лет</strong>, все виды работ по <strong>договору</strong>.
            <br />
            <span className="text-success">Гарантия на работу.</span> Исполнитель закупает материалы.
          </p>
          <div className="animated-bg z-1">
            <i className="icon" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature_section;
