import React from "react";
import main_photo from "../images/pexels-digital-buggu-368754.jpg";
import "../css/SliderSection.scss";

const SliderSection = () => {
  return (
    <section className="position-relative mt-2 overflow-hidden slider-section" id="slider">
      <img src={main_photo} alt="" className="position-relative top-0 left-0 w-100" />
      <div className="position-absolute top-0 left-0 w-100 h-100 bg-color-black opacity-50 filter-50 blur-light"></div>
      <div className="position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center flex-column flex-center px-2">
        <h1 className="text-white">Сухая стяжка пола / Укладка ламината / Скрипы</h1>
        <h4 className="text-white">Трансформируем ваш дом шаг за шагом</h4>
        <a href="tel:+79053492118" className="mt-5 px-4 py-3 call-btn fw-bold btn btn-primary">
          Позвонить: +7 (905) 349 2118
        </a>
      </div>
    </section>
  );
};

export default SliderSection;
