import React from "react";
import "../css/Header.scss";

const Header = () => {
  return (
    <header className="navigation position-relative d-flex flex-row flex-fill justify-content-between align-items-center border-bottom my-2">
      <div className="mx-5">Пол Александра</div>
      <ul className="nav mx-5">
        <li className="nav-item">
          <a className="nav-link text-decoration-none text-muted active" href="#price">
            Прайс-лист
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-decoration-none text-muted" href="#about">
            О компании
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-decoration-none text-muted pe-0" href="#contacts">
            Контакты
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
