import React from "react";
import "../css/Footer.scss";

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-4 pb-4" id="contacts">
      <div className="p-0 mx-5">
        <div className="d-flex justify-content-between flex-adaptive-custom">
          <div>
            <h4>Пол Александра</h4>
            <p>Укладка полов и другие напольные работы.</p>
          </div>
          <div>
            <h4>Контакты</h4>
            <a href="tel:+79053492118">Телефон: +7 905 349 2118</a>
            <p>Email: info@company.com</p>
          </div>
          <div>
            <h4>Адрес:</h4>
            <p>Ульяновская обл., Ульяновск, Локомотивная ул., 108 р-н Железнодорожный</p>
          </div>
          {/* <div>
            <h4>Соцсети</h4>
            <a href="#" className="text-white me-2">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="text-white me-2">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="text-white me-2">
              <i className="fab fa-instagram"></i>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
