import "./css/style.scss";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header.js";
import SliderSection from "./components/slider_section.js";
import Feature_section from "./components/feature_section.js";
import About_section from "./components/about_section.js";
import Footer from "./components/footer.js";

function App() {
  return (
    <>
      <Header />
      <SliderSection />
      <Feature_section />
      <About_section />
      <Footer />
    </>
  );
}

export default App;
