import React from "react";
import "../css/About_section.scss";

const About_section = () => {
  return (
    <section class="bg-about-section text-white p-5 mt-2 mb-5" id="about">
      <div class="container">
        <h2 class="text-center mb-4">О компании</h2>
        <p class="lead text-center">Мы предлагаем профессиональные услуги по укладке полов. ЗАМЕР БЕСПЛАТНО!!! ЗАКЛЮЧАЕМ ДОГОВОР!!! ГАРАНТИЯ 5 ЛЕТ!!!</p>

        <div class="row">
          <div class="col-md-6">
            <h3>Варианты укладки пола:</h3>
            <ul>
              <li>Сухая стяжка — используется перлит, вермикулит, керамзит (от 0,1 до 0,5 мм).</li>
              <li>Сборный пол — основание может быть усилено слоем листа "Кнауф".</li>
            </ul>
          </div>
          <div class="col-md-6">
            <h3>Цены:</h3>
            <p>Стоимость укладки пола варьируется от 500 до 1500 рублей за квадратный метр в зависимости от материала и сложности работ.</p>
          </div>
        </div>

        <h4 class="mt-5">Преимущества нашей работы:</h4>
        <ul>
          <li>Лёгкость конструкции.</li>
          <li>Скорость сборки основания под напольное покрытие.</li>
          <li>Сухая технология, исключающая протечки.</li>
        </ul>

        <p class="text-center mt-4">Работаем по всему городу! Звоните или пишите, приедем в любую точку города в удобное для вас время!</p>
      </div>
    </section>
  );
};

export default About_section;
